import request from '@/utils/request2'

// 获取待考试课程数/待学习课程数/待评价课程数接口
export function getPxTotal (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getPxTotal',
    method: 'get',
    params: { empid }
  })
}

// 获取待考试课程列表接口
export function getTestCourseList (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getTestCourseList',
    method: 'get',
    params: { empid }
  })
}

// 获取某个课程考试的汇总信息接口
export function getCourseTestTotal (params) {
  const { paperid } = params
  return request({
    url: '/api/wxpx/getCourseTestTotal',
    method: 'get',
    params: { paperid }
  })
}

// 生成当前用户当前课程考试试卷信息（并返回试题明细和上次中断序号信息）
export function createUserTestInfo (params) {
  const { mautoid, empid, username, teststyle, paperid } = params
  return request({
    url: '/api/wxpx/createUserTestInfo',
    method: 'get',
    params: { mautoid, empid, username, teststyle, paperid }
  })
}

//保存试卷某个试题答案信息接口（考试只能一直点下一题，不能切换到上一题）
export function saveTestQsInfo (params) {
  const { autoid, qsres, username } = params
  return request({
    url: '/api/wxpx/saveTestQsInfo',
    method: 'get',
    params: { autoid, qsres, username }
  })
}

// 获取试卷提交后的所有试题答案明细信息接口
export function getTestFinishInfo (params) {
  const { mautoid, empid, username, teststyle, paperid } = params
  return request({
    url: '/api/wxpx/getTestFinishInfo',
    method: 'get',
    params: { mautoid, empid, username, teststyle, paperid }
  })
}
// 获取待评价课程列表接口
export function getPjCourseList (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getPjCourseList',
    method: 'get',
    params: { empid }
  })
}

// 获取初始的课程评价或讲师评价问卷信息列表
export function getPjDetail (params) {
  const { iflag, mautoid, empid, paperid, username } = params
  return request({
    url: '/api/wxpx/getPjDetail',
    method: 'get',
    params: { iflag, mautoid, empid, paperid, username }
  })
}

// 提交课程评价/讲师评价问卷信息列表
export function savePjDetail (data) {
  return request({
    url: '/api/wxpx/savePjDetail',
    method: 'post',
    data: data
  })
}

// 获取提交后的课程评价/讲师评价结果信息列表
export function getFinishPjDetail (params) {
  const { iflag, mautoid, empid, paperid, username } = params
  return request({
    url: '/api/wxpx/getFinishPjDetail',
    method: 'get',
    params: { iflag, mautoid, empid, paperid, username }
  })
}

// 获取待学习课程列表接口
export function getStudyCourseList (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getStudyCourseList',
    method: 'get',
    params: { empid }
  })
}

// 获取课程各阶段课件列表接口
export function getStepWareInfo (params) {
  
  return request({
    url: '/api/wxpx/getStepWareInfo',
    method: 'get',
    params: params
  })
}

// 保存观看课件记录日志接口
export function saveWatchLog (params) {
  const { empid, mautoid, cw_autoid, username } = params
  return request({
    url: '/api/wxpx/saveWatchLog',
    method: 'get',
    params: { empid, mautoid, cw_autoid, username }
  })
}

// 获取当月有培训的日期列表
export function getSignDateList (params) {
  const { yymm, empid } = params
  return request({
    url: '/api/wxpx/getSignDateList',
    method: 'get',
    params: { yymm, empid }
  })
}

// 获取某天要培训课程详细信息列表
export function getSignCourseList (params) {
  const { yymmdd, empid } = params
  return request({
    url: '/api/wxpx/getSignCourseList',
    method: 'get',
    params: { yymmdd, empid }
  })
}

// 保存签到结果接口
export function saveSignRecord (params) {
  const { mautoid, empid, username } = params
  return request({
    url: '/api/wxpx/saveSignRecord',
    method: 'get',
    params: { mautoid, empid, username }
  })
}

// 保存签到结果接口
export function getSignEmpList (params) {
  const { mautoid, username } = params
  return request({
    url: '/api/wxpx/getSignEmpList',
    method: 'get',
    params: { mautoid, username }
  })
}


// 获取历史培训记录列表
export function getHisTrainRecordList (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getHisTrainRecordList',
    method: 'get',
    params: { empid }
  })
}

// 获取历史培训记录列表
export function getTrainRecordInfo (params) {
  const { autoid } = params
  return request({
    url: '/api/wxpx/getTrainRecordInfo',
    method: 'get',
    params: { autoid }
  })
}

// 获取历史考试记录列表
export function getHisPostPaperList (params) {
  const { empid } = params
  return request({
    url: '/api/wxpx/getHisPostPaperList',
    method: 'get',
    params: { empid }
  })
}

// 首次打开视频或文档调用接口
export function saveWatchLogStart (params) {
  const { empid, mautoid, cw_autoid, guid, videotimes, username } = params
  return request({
    url: '/api/wxpx/saveWatchLog_Start',
    method: 'get',
    params: params
  })
}

// 中途暂停或关闭页面调用的接口
export function saveWatchLogEnd (params) {
  const { empid, mautoid, cw_autoid, guid, videotimes, lastpos, username, isfinish } = params
  return request({
    url: '/api/wxpx/saveWatchLog_End',
    method: 'get',
    params: params
  })
}

// 获取某个课件的上次观看断点位置接口
export function getVideoLastPos (params) {
  const { empid, mautoid, cw_autoid, username } = params
  return request({
    url: '/api/wxpx/getVideoLastPos',
    method: 'get',
    params: params
  })
}

// 判断课件是否学习完成接口
export function checkWareFinish (params) {
  const { empid, mautoid, cw_autoid, username } = params
  return request({
    url: '/api/wxpx/checkWareFinish',
    method: 'get',
    params: params
  })
}

// 7.8.1判断当前用户是否有权限打开该问卷以及返回是否已经提交该问卷
export function getCommPjStatus (data) {
  return request({
    url: '/api/wxpx/getCommPjStatus',
    method: 'get',
    params: data
  })
}

// 7.8.2获取问卷提交前的详细信息接口
export function getCommPjDetail (data) {
  return request({
    url: '/api/wxpx/getCommPjDetail',
    method: 'get',
    params: data
  })
}

// 7.8.3提交问卷接口
export function saveCommPjDetail (data) {
  return request({
    url: '/api/wxpx/saveCommPjDetail',
    method: 'post',
    data: data
  })
}

// 7.8.4获取提交后的问卷信息接口
export function getCommFinishPjDetail (data) {
  return request({
    url: '/api/wxpx/getCommFinishPjDetail',
    method: 'get',
    params: data
  })
}

// 获取培训项目详细信息接口：
export function getTrainPrjInfo (data) {
  return request({
    url: '/api/wxpx/getTrainPrjInfo',
    method: 'get',
    params: data
  })
}

// 点击报名接口：
export function prjReqCheck (data) {
  return request({
    url: '/api/wxpx/prjReqCheck',
    method: 'get',
    params: data
  })
}