import request from '@/utils/request2'
// 获取钉钉Sigurate
export function getDdSigurate(data) {
  return request({
    url: '/api/wx/getDdSigurate',
    method: 'get',
    params: data
  })
}
// 根据code获取用户钉钉id
export function getDdUserid(data) {
  return request({
    url: '/api/wx/getDdUserid',
    method: 'get',
    params: data
  })
}
// 检测员工empid和钉钉id是否绑定
export function checkDdUserid(data) {
  return request({
    url: '/api/wx/checkDdUserid',
    method: 'get',
    params: data
  })
}
// 绑定员工empid和钉钉id的关系
export function bindEmpDdid(data) {
  return request({
    url: '/api/wx/bindEmpDdid',
    method: 'get',
    params: data
  })
}
