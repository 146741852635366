<template>
  <!-- 工作台 -->
  <div class="homeContainer">
    <div class="row1">
      <div class="title" v-show="$t('home.hrHelper')">
        <van-row>
          <van-col span="24" class="vancol24">
            <span>{{ $t('home.hrHelper') }}</span>
            <div class="header-top">
              <span class="home_head_qrcode" @click="row4Click('recommend')">
                <svg-icon icon-class="home_head_qrcode"></svg-icon>
              </span>
              <van-badge
                class="home_head_warn"
                :content="msgNum > 10 ? msgNum : null"
                :dot="msgNum > 0 && msgNum < 10"
              >
                <span @click="gotoMsg">
                  <svg-icon class="ee" icon-class="home_head_warn"></svg-icon>
                </span>
              </van-badge>
            </div>
          </van-col>
        </van-row>
      </div>
      <van-grid :border="false" :column-num="columnNumber" class="homeGrid">
        <van-grid-item
          v-for="(item, index) in row1"
          v-show="item.show"
          :key="index"
          @click="item.url.indexOf('appid:') < 0 && jump(item.url)"
        >
          <template v-if="item.url.indexOf('appid:') < 0">
            <svg-icon slot="icon" :icon-class="item.iconName"></svg-icon>
            <span slot="text">{{ item.name }}</span>
          </template>
          <div v-else v-html="reXCX(item)"></div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="newsCenter" v-show="shownews">
      <van-cell
        :title="$t('home.name43')"
        is-link
        :value="$t('home.all')"
        @click="jumpNews"
      />
      <div class="newsImg">
        <!-- <img src="@/assets/img/newsImg.png"
        alt="" />-->
        <van-swipe :autoplay="2000" touchable>
          <van-swipe-item v-for="(image, idx) in newsImages" :key="idx + 'a'">
            <!-- <img v-lazy="image" /> -->
            <img :src="image" alt />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="newsLists">
        <div
          class="newsDta"
          v-for="(it, index) in news"
          :key="index + 'b'"
          @click="goToDetails(it)"
        >
          <div class="dta_left">{{ it.newstitle }}</div>
          <div class="dta_right">
            {{ it.checkdate ? it.checkdate.split(' ')[0] : it.checkdate }}
          </div>
        </div>
      </div>
    </div>
    <!-- 自助申请 -->
    <div class="row3" v-if="showwf && $t('home.text17')">
      <van-cell
        :title="$t('home.text17')"
        is-link
        :value="$t('home.all')"
        @click="jumpApply()"
      />
      <van-grid :border="false" :column-num="columnNumber3" class="row3Grid">
        <van-grid-item
          v-for="(item, index) in row3.slice(0, 5)"
          :key="index + 'c'"
          @click="jump3(item.text, item.formautoid)"
        >
          <svg-icon slot="icon" :icon-class="item.iconName"></svg-icon>
          <span slot="text">{{ item.text }}</span>
        </van-grid-item>
      </van-grid>
      <van-grid
        v-if="columnNumber5 > 0"
        :border="false"
        :column-num="columnNumber5"
        class="row3Grid"
      >
        <van-grid-item
          v-for="(item, index) in row3.slice(5)"
          :key="index + 'd'"
          @click="jump3(item.text, item.formautoid)"
        >
          <svg-icon slot="icon" :icon-class="item.iconName"></svg-icon>
          <span slot="text">{{ item.text }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 自助查询 -->
    <div class="row2" v-if="showhr && $t('home.text18')">
      <van-cell
        :title="$t('home.text18')"
        is-link
        :value="$t('home.all')"
        @click="jumpQuery()"
      />
      <van-grid :border="false" :column-num="5" class="row2Grid xiba">
        <van-grid-item
          v-for="(item, index) in row2.slice(0, 5)"
          :key="index + 'q'"
          @click="jump2(item.hyrptparas)"
        >
          <svg-icon slot="icon" :icon-class="item.iconName"></svg-icon>
          <span slot="text">{{ item.text }}</span>
        </van-grid-item>
      </van-grid>
      <van-grid
        v-if="columnNumber4 > 0"
        :border="false"
        :column-num="columnNumber4"
        class="row2Grid"
      >
        <van-grid-item
          v-for="(item, index) in row2.slice(5)"
          :key="index + 'w'"
          @click="jump2(item.hyrptparas)"
        >
          <svg-icon slot="icon" :icon-class="item.iconName"></svg-icon>
          <span slot="text">{{ item.text }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="row4" v-show="showzp">
      <van-cell
        :title="$t('home.intRec')"
        style="align-items: center"
        @click="row4Click('recommend')"
      >
        <template #right-icon>
          <svg-icon
            class="home_blue_qrcode"
            slot="icon"
            icon-class="home_blue_qrcode"
          ></svg-icon>
        </template>
      </van-cell>
      <div class="row4b_container">
        <div class="point1"></div>
        <div class="point2"></div>
        <div class="row4b_box">
          <div
            style="border-right: 1px solid #ccc"
            class="row4_box"
            @click="row4Click('interview')"
          >
            <p style="color: #16bb83" class="number">{{ interviewNum }}</p>
            <p class="text">{{ $t('home.text1') }}</p>
          </div>
          <div
            style="border-right: 1px solid #ccc"
            class="row4_box"
            @click="row4Click('confirm')"
          >
            <p style="color: #2b8df0" class="number">{{ inductionNum }}</p>
            <p class="text">{{ $t('home.text2') }}</p>
          </div>
          <div class="row4_box" @click="row4Click('turning')">
            <p style="color: #2b8df0" class="number">{{ positiveNum }}</p>
            <p class="text">{{ $t('home.text3') }}</p>
          </div>
        </div>
        <div class="box_footer_Item">
          <img src="@/assets/img/home_block_footer.png" alt />
        </div>
      </div>
    </div>
    <div class="row4" v-show="showpx">
      <van-cell :title="$t('home.text5')" />
      <div class="row4b_container">
        <div
          class="point1"
          style="right: 0.42667rem; left: auto; top: -1rem"
        ></div>
        <div
          class="point2"
          style="right: 0.75rem; left: auto; top: -0.7rem"
        ></div>
        <div class="row4b_box" style="border-bottom: 1px solid #ccc">
          <div
            :style="
              index == row5.length - 1 ? '' : 'border-right: 1px solid #ccc;'
            "
            class="row4_box"
            v-for="(item, index) in row5"
            :key="index + 'e'"
            @click="row5Click(item)"
          >
            <p :style="{ color: item.color }" class="number">{{ item.num }}</p>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
        <div class="row4b_box">
          <div
            :style="
              index == row5D.length - 1 ? '' : 'border-right: 1px solid #ccc;'
            "
            class="row4_box"
            v-for="(item, index) in row5D"
            :key="index + 'r'"
            @click="row5GridClick(item)"
          >
            <p :style="{ color: item.color }" class="number">{{ item.num }}</p>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
        <div class="box_footer_Item">
          <img src="@/assets/img/home_block_footer.png" alt />
        </div>
      </div>
    </div>
    <div class="row4" v-show="showjx">
      <van-cell :title="$t('home.text6')" />
      <div class="row4b_container">
        <div
          class="point1"
          style="right: 0.42667rem; left: auto; top: -1rem"
        ></div>
        <div
          class="point2"
          style="right: 0.75rem; left: auto; top: -0.7rem"
        ></div>
        <div class="row6b_box">
          <div
            class="row4_box"
            :class="
              row6T.length == 0 &&
              index ==
                row6.filter(it => {
                  return it.text
                }).length -
                  1
                ? 'box_border'
                : row6T.length == 0 &&
                  index ==
                    row6.filter(it => {
                      return it.text
                    }).length -
                      2
                ? 'box_border'
                : row6T.length == 0 &&
                  index ==
                    row6.filter(it => {
                      return it.text
                    }).length -
                      3 &&
                  index != 5
                ? 'box_border'
                : ''
            "
            v-for="(item, index) in row6.filter(it => {
              return it.text
            })"
            :key="index + 'f'"
            :style="
              index == 2
                ? ''
                : index == 5
                ? ''
                : index == 8
                ? ''
                : 'border-right: 1px solid #ccc;'
            "
            @click="row6Click(item)"
          >
            <p v-if="item.text" :style="{ color: item.color }" class="number">
              {{ item.num }}
            </p>
            <p class="text">{{ item.text }}</p>
          </div>
          <div
            :style="index % 3 == 2 ? '' : 'border-right: 1px solid #ccc;'"
            class="row4_box"
            v-for="(item, index) in row6T.filter(it => {
              return it.text
            })"
            :key="index + 'u'"
            @click="row6GridClick(item)"
          >
            <p v-if="item.text" :style="{ color: item.color }" class="number">
              {{ item.num }}
            </p>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
        <!-- <div class="row4b_box"
             style="border-bottom: 1px solid #ccc;">
          <div :style="(index == (row5D.length-1))?'':'border-right: 1px solid #ccc;'"
               class="row4_box"
               v-for="(item, index) in row6D"
               :key="index"
               @click="row6GridClick(item)">
            <p :style="{ color:item.color }"
               class="number">{{ item.num }}</p>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
        <div class="row4b_box">
          <div :style="(index == (row5D.length-1))?'':'border-right: 1px solid #ccc;'"
               class="row4_box"
               v-for="(item, index) in row6S"
               :key="index"
               @click="row6GridClick(item)">
            <p v-if="item.text"
               :style="{ color:item.color }"
               class="number">{{ item.num }}</p>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>-->

        <div class="box_footer_Item">
          <img src="@/assets/img/home_block_footer.png" alt />
        </div>
      </div>
    </div>
    <!-- <van-dialog v-model="showQRcode" title :showConfirmButton="false">
      <van-icon name="cross" @click="closeEwm" />
      <div class="ewm">
        <qrcode :text="recommendUrl" />
      </div>
      <p>
        {{ $t("home.text7") }}
        <br />
      </p>
    </van-dialog>-->
    <ddBind ref="ddBind" />
  </div>
</template>

<script>
import { Lazyload, Dialog } from 'vant'
import { getZpTotal } from '@api/wxzp.js'
import { getPxTotal } from '@api/wxpx.js'
import { getJxCount } from '@api/wxjx.js'
import {
  getQueryAndReqList,
  getWxTopBtnList,
  getTopNewsList,
  getAlarmMsgCount,
  getSalarySignParam
} from '@api/wx.js'
import { isChangePwd } from '@api/login.js'
import ddBind from '@components/ddBind'
import { GetJsSign } from '@api/hp.js'
// import qrcode from '@components/qrcode'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  components: {
    ddBind
    // qrcode
  },
  inject: ['reload'],
  name: 'homeContainer',
  data() {
    return {
      userInfo,
      interviewNum: 0, // 待面试人数
      inductionNum: 0, // 待入职人数
      positiveNum: 0, // 待转正人数
      msgNum: 0, // 未读消息数量
      row1: [
        {
          id: 1,
          iconName: 'hrow1_1',
          name: this.$t('home.name1'),
          url: '@v3url/weixinlocal/queryrpt/yidongqiandao.html',
          show: false
        },
        {
          id: 2,
          iconName: 'hrow1_2',
          name: this.$t('home.name2'),
          url: '',
          show: false
        },
        {
          id: 3,
          iconName: 'hrow1_3',
          name: this.$t('home.name3'),
          url: '',
          show: false
        },
        {
          id: 4,
          iconName: 'hrow1_4',
          name: this.$t('home.name4'),
          url: '',
          show: false
        },
        {
          id: 5,
          iconName: 'hrow1_5',
          name: this.$t('home.name5'),
          url: '',
          show: false
        },
        {
          id: 6,
          iconName: 'hrow1_6',
          name: this.$t('home.name6'),
          url: '/weixinlocal/work/EntCalendar.html',
          show: false
        },
        {
          id: 7,
          iconName: 'hrow1_7',
          name: this.$t('home.name7'),
          url: '',
          show: false
        },
        {
          id: 8,
          iconName: 'hrow1_8',
          name: this.$t('home.name8'),
          url: '',
          show: false
        },
        {
          id: 9,
          iconName: 'hrow1_9',
          name: this.$t('home.name9'),
          url: '',
          show: false
        },
        {
          id: 10,
          iconName: 'hrow1_10',
          name: this.$t('home.name10'),
          url: '/weixinlocal/EmpPic_H5/main.html',
          show: false
        },
        {
          id: 11,
          iconName: 'hrow1_11',
          name: this.$t('home.name11'),
          url: 'http://sztest.fabigbig.com/newdemo/',
          show: false
        },
        {
          id: 12,
          iconName: 'hrow1_12',
          name: this.$t('home.name12'),
          url: '@v3url/order/index.html',
          show: false
        },
        {
          id: 13,
          iconName: 'hrow1_12',
          name: this.$t('home.name12'),
          url: '/weixinlocal/queryrpt/dingcan2.html',
          show: false
        },
        {
          id: 14,
          iconName: 'hrow1_14',
          name: this.$t('home.name40'),
          url: '/weixinlocal/queryrpt/dwsp.html',
          show: false
        },
        {
          id: 15,
          iconName: 'hrow1_15',
          name: this.$t('home.name41'),
          url: '/weixinlocal/queryrpt/wfqd.html',
          show: false
        },
        {
          id: 16,
          iconName: 'hrow1_16',
          name: this.$t('home.name42'),
          url: '/weixinlocal/queryrpt/wysp.html',
          show: false
        },
        {
          id: 17,
          iconName: 'hrow1_17',
          name: '工资签收',
          url: '/salarySiginPassword',
          show: false
        },
        {
          id: 24,
          iconName: 'hrow1_24',
          name: '套餐订餐',
          url: '/weChatOrdering',
          show: false
        },
        {
          id: 25,
          iconName: 'hrow1_25',
          name: '扫码消费',
          url: '/EastRiverQrcode',
          show: false
        }
      ],
      columnNumber: 4,
      // columnNumber2: 5,
      columnNumber3: 5,
      columnNumber4: 0,
      columnNumber5: 0,
      row2: [
        {
          iconName: 'hrow2_1',
          text: this.$t('home.name13'),
          hyrptparas: '员工信息|dangan.html|0|201|'
        },
        {
          iconName: 'hrow2_2',
          text: this.$t('home.name14'),
          hyrptparas: '请假申请|Template4.html|1|612|bd'
        },
        {
          iconName: 'hrow2_3',
          text: this.$t('home.name15'),
          hyrptparas: '考勤日报|rikaoqin.html|1|660|yymmdd'
        },
        {
          iconName: 'hrow2_4',
          text: this.$t('home.name16'),
          hyrptparas: '补休申请|Template4.html|1|614|bd'
        },
        {
          iconName: 'hrow2_5',
          text: this.$t('home.name17'),
          hyrptparas: '加班申请|Template4.html|1|611|yymmdd'
        },
        {
          iconName: 'hrow2_6',
          text: this.$t('home.name13'),
          hyrptparas: '员工信息|dangan.html|0|201|'
        },
        {
          iconName: 'hrow2_7',
          text: this.$t('home.name14'),
          hyrptparas: '请假申请|Template4.html|1|612|bd'
        },
        {
          iconName: 'hrow2_8',
          text: this.$t('home.name15'),
          hyrptparas: '考勤日报|rikaoqin.html|1|660|yymmdd'
        },
        {
          iconName: 'hrow2_9',
          text: this.$t('home.name16'),
          hyrptparas: '补休申请|Template4.html|1|614|bd'
        },
        {
          iconName: 'hrow2_10',
          text: this.$t('home.name17'),
          hyrptparas: '加班申请|Template4.html|1|611|yymmdd'
        }
      ],
      row3: [
        { iconName: 'hrow3_1', text: this.$t('home.name18'), formautoid: 26 },
        { iconName: 'hrow3_2', text: this.$t('home.name19'), formautoid: 27 },
        { iconName: 'hrow3_3', text: this.$t('home.name17'), formautoid: 25 },
        { iconName: 'hrow2_4', text: this.$t('home.name20'), formautoid: 36 },
        { iconName: 'hrow3_5', text: this.$t('home.name21'), formautoid: 38 },
        { iconName: 'hrow3_6', text: this.$t('home.name18'), formautoid: 26 },
        { iconName: 'hrow3_7', text: this.$t('home.name19'), formautoid: 27 },
        { iconName: 'hrow3_8', text: this.$t('home.name17'), formautoid: 25 },
        { iconName: 'hrow2_9', text: this.$t('home.name20'), formautoid: 36 },
        { iconName: 'hrow3_10', text: this.$t('home.name21'), formautoid: 38 }
      ],
      row4D: [
        { iconName: 'hrow4_1', text: this.$t('home.name22') },
        { iconName: 'hrow4_2', text: this.$t('home.name23') },
        { iconName: 'hrow4_3', text: this.$t('home.name24') },
        { iconName: 'hrow4_4', text: this.$t('home.name25') }
      ],
      row5: [
        { num: 0, text: this.$t('home.name26'), color: '#2B8DF0' },
        { num: 0, text: this.$t('home.name27'), color: '#16BB83' },
        { num: 0, text: this.$t('home.name28'), color: '#FFA006' }
      ],
      row5D: [
        { num: 0, text: this.$t('home.name29'), color: '#EF4723' },
        { num: 0, text: this.$t('home.name30'), color: '#2B8DF0' },
        { num: 0, text: this.$t('home.name31'), color: '#2B8DF0' }
      ],
      row6: [
        { num: 0, text: this.$t('home.text8'), goto: 'khzb', color: '#16BB83' },
        { num: 0, text: this.$t('home.text9'), goto: 'khjg', color: '#F53838' },
        {
          num: 0,
          text: this.$t('home.text10'),
          goto: 'spzb',
          color: '#2B8DF0'
        },
        {
          num: 0,
          text: this.$t('home.text11'),
          goto: '360qr',
          color: '#2B8DF0'
        },
        {
          num: 0,
          text: this.$t('home.text12'),
          goto: '360jl',
          color: '#FFA006'
        },
        {
          num: 0,
          text: this.$t('home.text13'),
          goto: 'KPIjl',
          color: '#16BB83'
        },
        {
          num: 0,
          text: this.$t('home.text14'),
          goto: 'jxss',
          color: '#2B8DF0'
        },
        {
          num: 0,
          text: this.$t('home.text15'),
          goto: 'jxmt',
          color: '#FFA006'
        },
        { num: 0, text: this.$t('home.text16'), goto: 'tjsj', color: '#16BB83' }
      ],
      row6D: [
        { num: 0, text: '360评价人确认', goto: 'induction', color: '#2B8DF0' },
        { num: 0, text: '360待评价记录', goto: 'induction', color: '#FFA006' },
        { num: 0, text: 'KPI待评价记录', goto: 'induction', color: '#16BB83' }
      ],
      row6S: [
        { num: 0, text: '绩效申诉', goto: 'induction', color: '#2B8DF0' },
        { num: 0, text: '绩效面谈', goto: 'induction', color: '#FFA006' },
        { num: 0, text: '', goto: 'induction', color: '#16BB83' }
      ],
      row6T: [
        {
          num: 0,
          text: this.$t('GSTask.ordTask'),
          goto: 'ordTask?type=1',
          color: '#2B8DF0'
        },
        {
          num: 0,
          text: this.$t('GSTask.appTask'),
          goto: 'ordTask?type=2',
          color: '#FFA006'
        },
        {
          num: 0,
          text: this.$t('GSTask.exaTask'),
          goto: 'exaTask',
          color: '#16BB83'
        },
        {
          num: 0,
          text: this.$t('GSTask.subTask'),
          goto: 'subTask',
          color: '#16BB83'
        },
        {
          num: 0,
          text: this.$t('GSTask.evaTask'),
          goto: 'evaTask',
          color: '#F53838'
        },
        {
          num: 0,
          text: this.$t('GSTask.queTask'),
          goto: 'queTask',
          color: '#2B8DF0'
        }
      ],
      wxdata: {
        entname: this.$t('home.name39'),
        entcode: '60',
        empcode: '001',
        empname: 'zhaorushan',
        userid: '001',
        pwd: '001',
        nickname: 'zhaorushan',
        existpic: '0',
        empid: '86457'
      },
      V3url:
        //process.env.NODE_ENV === 'production' ? window.V3url :
        './',
      showQRcode: false,
      recommendUrl: '', // 内推二维码
      showjx: false,
      showpx: false,
      showzp: false,
      showhr: false, // 查询中心
      showwf: false, // 申请中心
      shownews: false, // 新闻中心
      news: [],
      KhCount: 0, // 我的指标”数
      newsImages: [
        require('@/assets/img/newsImg.png'),
        require('@/assets/img/newsImg.png')
      ],
      row6Fitlength: 0
    }
  },
  created() {
    this.getQueryAndReqList()
    this.getWxTopBtnList()
    this.getZpTotalData()
    this.getPxTotalData() //获取待考试课程数/待学习课程数/待评价课程数
    this.getNewsList() // 获取新闻
    this.getMyKhCountData() // 获取“我的指标”数
    this.getAlarmMsgCount() // 获取首页未读消息数量
    if (window.APP_ID) this.weixinConfig()
    // alert(window.globalurls)
  },
  mounted() {
    this.row6Fitlength = this.row6.filter(it => {
      return it.text
    }).length
  },
  methods: {
    // 获取“我的指标”数
    getMyKhCountData() {
      getJxCount({ empid: this.userInfo.empid }).then(res => {
        // console.log(res)
        let data = res.data
        // this.KhCount = res.data[0].totalcount
        this.row6[0].num = data[0].c1
        this.row6[1].num = data[0].c2
        this.row6[2].num = data[0].c3
        this.row6[3].num = data[0].c4
        this.row6[4].num = data[0].c5
        this.row6[5].num = data[0].c6
        this.row6[6].num = data[0].c7
        this.row6[7].num = data[0].c8
        this.row6[8].num = data[0].c9
        this.row6T[0].num = data[0].c10
        this.row6T[1].num = data[0].c11
        this.row6T[2].num = data[0].c12
        this.row6T[3].num = data[0].c13
        this.row6T[4].num = data[0].c14
        this.row6T[5].num = data[0].c15
      })
    },
    closeEwm() {
      this.showQRcode = false
    },
    // 获取新闻新增列表前十数据
    getNewsList() {
      getTopNewsList().then(res => {
        this.news = []
        this.news = res.data.slice(0, 3) // 截取前3个
      })
    },
    // 获取首页的自助查询和自助申请列表接口
    getQueryAndReqList() {
      getQueryAndReqList({
        empid: this.userInfo.empid,
        username: this.userInfo.username
      }).then(res => {
        // console.log(res, 'reeeeeee')
        let len1 = res.querylist.length
        let len2 = res.reqlist.length
        if (len1 > 5) {
          this.columnNumber4 = 5
        }
        if (len2 > 5) {
          this.columnNumber3 = 5
          this.columnNumber5 = 5
        } else {
          // this.columnNumber3 = len2
        }
        this.row2 = []
        res.querylist.forEach((e, i) => {
          if (i <= 9) {
            // console.log(i, 111)
            let url = '',
              Flag = e.apptemplateid.toString(),
              moduleno = e.moduleno.toString()
            if (Flag == '0') {
              if (moduleno == '610') url = 'shuaka.html'
              if (moduleno == '615') url = 'qianka.html'
              if (moduleno == '611') url = 'jiaban.html'
              if (moduleno == '1005') url = 'dingcan.html'
              if (moduleno == '1007') url = 'xiaofei.html'
              if (moduleno == '205') url = 'jiangcheng.html'
              if (moduleno == '612') url = 'qingjia.html'
              if (moduleno == '660') url = 'rikaoqin.html'
              if (moduleno == '661') url = 'yuekaoqin.html'
              if (moduleno == '201') url = 'dangan.html'
              if (moduleno == '730') url = 'gongzi.html' //注意，先输工资密码框，在这个方法之前
            } else {
              url = 'Template' + Flag + '.html'
            }
            let it = {
              iconName: 'hrow2_' + (i + 1),
              text: e.modulename,
              hyrptparas:
                e.modulename +
                '|' +
                url +
                '|' +
                e.apptemplateid +
                '|' +
                e.moduleno +
                '|' +
                e.app_orderfield
            }
            this.row2.push(it)
          }
        })
        this.row3 = []
        res.reqlist.forEach((e, i) => {
          if (i <= 9) {
            e.iconName = 'plat' + e.appiconid
            e.text = e.formname
            this.row3.push(e)
          }
        })
      })
    },
    // 获取首页顶部4个按钮的标题序号和名称信息接口
    getWxTopBtnList() {
      getWxTopBtnList({
        username: this.userInfo.username
      }).then(res => {
        console.log(res, 'rrrr')
        let lists = res.btnlist
        let lengths = lists.length
        if (lengths >= 4) {
          this.columnNumber = 4
        } else {
          this.columnNumber = lists.length
        }
        if (lists.length > 0) {
          lists.forEach(ele => {
            this.row1.forEach(it => {
              if (it.id == ele.id) {
                if (ele.url.indexOf('@v3url') != -1) {
                  ele.url =
                    (window.V3url ? window.V3url : '') +
                    ele.url.split('@v3url')[1]
                }
                if (ele.id == 17) {
                  ele.url = '/salarySiginPassword'
                } else if (ele.id == 24) {
                  ele.url = '/weChatOrdering'
                } else if (
                  ele.id == 10 &&
                  (window.use_new_cap == 1 || window.use_new_cap == '1')
                ) {
                  ele.url = '/faceCollection'
                } else if (ele.id == 25) {
                  ele.url = '/EastRiverQrcode'
                }
                ele.iconName = it.iconName
                ele.show = true
              } else if (ele.url.indexOf('appid:') >= 0) {
                ele.show = true
              }
            })
          })
          this.row1 = lists
        }

        // 20221015 默认隐藏全部框，如果没返回panellist 再全部显示
        if (res.panellist) {
          // 显示区域
          this.showjx = res.panellist[0].showjx
          this.showpx = res.panellist[0].showpx
          this.showzp = res.panellist[0].showzp
          this.showhr = res.panellist[0].showhr
          this.showwf = res.panellist[0].showwf
          this.shownews = res.panellist[0].shownews
        } else {
          this.showjx = true
          this.showpx = true
          this.showzp = true
          this.showhr = true
          this.showwf = true
          this.shownews = true
        }
        if (res.panellist.length > 0) {
          // 显示头像
          var url = res.panellist[0].cappicurl + '?v=' + Date.parse(new Date())
          let img = new Image()
          img.src = url
          img.onload = () => {
            //判断图片是否加载成功
            window.sessionStorage.setItem('loginUserImage', url)
          }
        }
      })
    },
    // 待面试
    getZpTotalData() {
      getZpTotal({ empid: this.userInfo.empid }).then(res => {
        this.interviewNum = res.data[0].icount
        this.inductionNum = res.data[0].ivcheck_count
        this.positiveNum = res.data[0].zz_count
      })
    },
    // 获取待考试课程数/待学习课程数/待评价课程数 ( C1:待考试课程数,C2:待学习课程数,C3:待评价课程数,c4:培训签到数,c5:考试记录数,c6:培训记录数)
    getPxTotalData() {
      getPxTotal({ empid: this.userInfo.empid }).then(res => {
        this.row5[0].num = res.data[0].c1
        this.row5[1].num = res.data[0].c2
        this.row5[2].num = res.data[0].c3
        this.row5D[0].num = res.data[0].c4
        this.row5D[1].num = res.data[0].c5
        this.row5D[2].num = res.data[0].c6
      })
    },
    // 获取首页未读消息数量
    getAlarmMsgCount() {
      getAlarmMsgCount({ empid: this.userInfo.empid }).then(res => {
        this.msgNum = res.data[0].icount
      })
    },
    // 培训点击事件
    row5Click(item) {
      if (item.text == this.$t('home.name26')) {
        this.$router.push('/toExamined')
      } else if (item.text == this.$t('home.name27')) {
        this.$router.push('/toLearn')
      } else if (item.text == this.$t('home.name28')) {
        this.$router.push('/toEvaluated')
      }
    },
    // 学习
    row5GridClick(item) {
      if (item.text == this.$t('home.name29')) {
        this.$router.push('/signinTraining')
      } else if (item.text == this.$t('home.name31')) {
        this.$router.push({
          path: '/trainRecords'
        })
      } else if (item.text == this.$t('home.name30')) {
        this.$router.push('/examRecord')
      }
    },
    // 待面试人数 ___
    row4Click(item) {
      if (item === 'interview') {
        this.$router.push('/toInterview')
      } else if (item === 'induction') {
        // 待入职人数
        this.$router.push('/toInduction')
      } else if (item === 'recommend') {
        // 内推二维码
        // this.recommendUrl =
        //   (window.VUE_APP_DOMAIN
        //     ? window.VUE_APP_DOMAIN
        //     : 'https://v8.hyclound.cn:8090') +
        //   '/#/scanEntry?module=304&restype=内推&tj_empid=' +
        //   this.userInfo.empid
        // this.showQRcode = true
        this.$router.push('/reqrcode')
      } else if (item == 'confirm') {
        // 待面试确认
        this.$router.push('/confirmInterview')
      } else if (item == 'turning') {
        // 待转正确认
        this.$router.push('/turningPositive')
      }
    },
    row4GridClick(item) {
      if (item.text == this.$t('home.name22')) {
        this.$router.push('/resumeSel')
      } else if (item.text == this.$t('home.name23')) {
        this.$router.push('/arrangeInterview')
      } else if (item.text == this.$t('home.name24')) {
        this.$router.push('/sendOffer')
      } else if (item.text == this.$t('home.name25')) {
        this.$router.push('/applyEntry')
      }
    },
    // 绩效平台
    row6Click(item) {
      // jxDataSocre
      if (item.goto == 'khzb') {
        // 我的考核指标
        this.$router.push('/jxMyAssTarget')
      } else if (item.goto == 'khjg') {
        //我的考核结果
        this.$router.push('/jxMyAssResults')
      } else if (item.goto == 'spzb') {
        //待审批指标
        this.$router.push('/jxApprovalTag')
      } else if (item.goto == '360jl') {
        //360待评价记录
        this.$router.push('/jxWhenMyAssesList')
      } else if (item.goto == '360qr') {
        //360评价人确认
        this.$router.push('/jxAppraiserReview')
      } else if (item.goto == 'KPIjl') {
        // KPI待评价记录
        this.$router.push('/jxDataSocre')
      } else if (item.goto == 'jxss') {
        //绩效申诉
        this.$router.push('/jxPerformanceAppeals')
      } else if (item.goto == 'jxmt') {
        //绩效面谈
        this.$router.push('/jxPerformanceInterview')
      } else if (item.goto == 'tjsj') {
        //统计数据
        this.$router.push('/jxStatisticalData')
      }
    },
    row6GridClick(item) {
      // console.log(item)
      var GS = [
        'ordTask?type=1',
        'ordTask?type=2',
        'exaTask',
        'subTask',
        'evaTask',
        'queTask'
      ] //GS任务
      if (item.text == '360待评价记录') {
        this.$router.push('/jxWhenMyAssesList')
      } else if (item.text == '360评价人确认') {
        this.$router.push('/jxAppraiserReview')
      } else if (item.text == 'KPI待评价记录') {
        // KPI待评价记录
        this.$router.push('/jxDataSocre')
      } else if (item.text == '绩效申诉') {
        this.$router.push('/jxPerformanceAppeals')
      } else if (item.text == '绩效面谈') {
        this.$router.push('/jxPerformanceInterview')
      } else if (GS.indexOf(item.goto) >= 0) {
        //GS任务
        this.$router.push('/' + item.goto)
      }
    },
    // 界面跳转
    jump(u) {
      // 工资签收
      if (u == '/salarySiginPassword') {
        // this.$router.push({ path: '/salarySiginPassword' })
        getSalarySignParam({
          empcode: this.userInfo.empcode
        }).then(res => {
          let checkmode = res.data[0].checkmode
          let firstchangepwd = res.data[0].firstchangepwd
          let isfirstlogin = res.data[0].isfirstlogin
          let nosign = res.data[0].nosign
          if (isfirstlogin && firstchangepwd) {
            // 如果是首次打开并且 首次打开要强制修改密码 就去改密码界面
            this.$router.push({
              path: '/changeSalaryPsw',
              query: {
                from: 'checkSalaryPwd',
                firstchangepwd: firstchangepwd,
                isfirstlogin: isfirstlogin,
                nosign: nosign
              }
            })
          } else {
            this.$router.push({ path: '/salarySiginPassword' })
          }
          // if (checkmode == 1)          {
          //   // 密码验证
          //   this.$router.push({
          //     path: '/salarySiginPassword',
          //     query: { firstchangepwd: firstchangepwd, isfirstlogin: isfirstlogin, nosign: nosign }
          //   })
          // } else          {
          //   // 短信验证
          //   this.$router.push({
          //     path: '/smsVerification',
          //     query: { firstchangepwd: firstchangepwd, isfirstlogin: isfirstlogin, nosign: nosign }
          //   })
          // }
        })
      } else if (u == '/weChatOrdering') {
        // 套餐订餐
        this.$router.push({ path: '/weChatOrdering', query: { from: 'home' } })
      } else if (u == '/faceCollection') {
        // 人脸采集
        this.$router.push({ path: '/faceCollection', query: { from: 'home' } })
      } else if (u == '/EastRiverQrcode') {
        // 扫码消费
        this.$router.push({ path: '/EastRiverQrcode', query: { from: 'home' } })
      } else {
        let url = ''
        if (u.indexOf('http') > -1) {
          url = u
        } else {
          url = this.V3url + u
        }
        if (
          url.indexOf('yidongqiandao') > -1 ||
          url.indexOf('EmpPic_H5') > -1 ||
          url.indexOf('open.weixin.qq.com') > -1
        ) {
          if (localStorage.isYZJ === '1') {
            // alert(1)
            url = url.replace('weixinlocal', 'yunzhijialocal')
          }
          window.location.href = url
        } else {
          this.$router.push({
            path: '/jump',
            query: { url: url, time: new Date().getTime() }
          })
        }
        this.reload()
      }
    },
    // 界面跳转
    jump2(hyrptparas) {
      // 先判断HR查询是否启用新版界面，1启用新版界面，
      const use_new_hrquery = window.use_new_hrquery
      if (use_new_hrquery == 1 || use_new_hrquery == '1') {
        this.$router.push({
          path: '/commHrQueryPage',
          query: {
            moduleno: hyrptparas.split('|')[3],
            fromH5: true,
            fromPage: 'home'
          }
        })
      } else {
        setLocVal('hyrptparas', hyrptparas) //判断版本   v3-->1   v2008-->0
        // moduleno == "730" 需要跳转到工资密码界面
        let href =
          this.V3url +
          '/weixinlocal/queryrpt/' +
          (hyrptparas.split('|')[3] === '730'
            ? 'checkcode.html'
            : hyrptparas.split('|')[1])
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
        this.reload()
      }
    },
    // 界面跳转
    jump3(appname, formautoid) {
      var url = 'shengpiinfo.html'
      var title = appname
      setLocVal('hyrptparas', title + '|' + '' + url + '|' + '' + formautoid)
      let href = this.V3url + '/weixinlocal/queryrpt/' + url
      this.$router.push({
        path: '/jump',
        query: { url: href, time: new Date().getTime() }
      })
      this.reload()
      // location.href = this.V3url + '/weixinlocal/queryrpt/' + url
    },
    // 跳转新闻中心
    jumpNews() {
      this.$router.push('/newsCenter')
    },
    // 跳转查询
    jumpQuery() {
      this.$router.push('/query')
    },
    // 跳转申请
    jumpApply() {
      this.$router.push('/apply')
    },
    // 跳转新闻详情
    goToDetails(it) {
      const from = 'home'
      this.$router.push({
        path: '/newsDetails',
        query: { autoid: it.autoid, from: from }
      })
    },
    gotoMsg() {
      const from = 'home'
      this.$router.push('/msgCenter')
    },
    // 检查是否需要修改密码
    checkPwd() {
      isChangePwd({ username: this.userInfo.username }).then(res => {
        // console.log(res, 111111)

        if (res.topvalue) {
          switch (res.topvalue) {
            case '0':
              break

            case '1':
              if (!sessionStorage.pwdNoMsg) {
                sessionStorage.pwdNoMsg = 1
                Dialog.confirm({
                  message: res.errormsg
                })
                  .then(() => {
                    this.$router.push('/changePassword')
                  })
                  .catch(() => {
                    localStorage.setItem('v8_login_login', '0')
                    localStorage.removeItem('userid')
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('OpenId')
                    this.$nextTick(() => {
                      this.$router.push('/login')
                    })
                  })
              }
              break

            case '2':
              Dialog.alert({
                message: res.errormsg
              }).then(() => {
                this.$router.push('/changePassword')
              })
              break
            case '3':
              if (!sessionStorage.pwdNoMsg) {
                sessionStorage.pwdNoMsg = 1
                Dialog.alert({
                  message: res.errormsg
                }).then(() => {
                  this.$router.push('/changePassword')
                })
              }
              break
            default:
              break
          }
        }
      })
    },
    //生成小程序标签
    reXCX(item) {
      let urlarr = item.url.split(',')
      //'appid:gh_c297eaa4bb85,pages/index/index'
      let html =
        '<wx-open-launch-weapp id="XCX" username="' +
        urlarr[0].replace('appid:', '') +
        '" path="' +
        urlarr[1] +
        '" ><template><style>.text { color:#fff}.ico { width:35px;height:35px;}.box{text-align: center;}</style><div class="box"><div><img class="ico" src="' +
        require('@/assets/img/xcx.png') +
        '" /></div><div class="text">' +
        item.name +
        '</div></div></template></wx-open-launch-weapp>'
      return html
    },
    // 获取wx jssdk并且开放 wx-open-launch-weapp 跳转小程序标签
    weixinConfig() {
      // let mm = encodeURIComponent(location.href)
      //在vue里，我们把encodeURIComponent去掉了，反倒问题解决了
      let mm = location.href
      GetJsSign({
        url: mm
        // , entid: 7
      }).then(res => {
        wx.config({
          debug: false,
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ['openLocation', 'getLocation'],
          openTagList: ['wx-open-launch-weapp']
        })

        wx.ready(function() {
          // alert('config ready')
          console.log('config ready')
          // 7.2 获取当前地理位
        })
        wx.error(function(w) {
          console.log('config error', w)
        })
      })
    }
  },
  mounted() {
    // console.log(this.userInfo)
    setLocVal('entver', '1') //判断版本   v3-->1   v2008-->0
    setLocVal('state', window.CloundEntid) //判断版本   v3-->1   v2008-->0

    let login = localStorage.userInfo
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    setLocVal('hyrptparas-autoid', '')
    setLanguage(localStorage.getItem('langid'))
    if (!login || window.CloundEntid == '7') {
      SetUserLocal(
        this.wxdata.entname,
        this.wxdata.entcode,
        this.wxdata.empcode,
        this.wxdata.empname,
        this.wxdata.userid,
        this.wxdata.pwd,
        this.wxdata.nickname,
        this.wxdata.existpic,
        this.wxdata.empid
      )
      setLocVal('spusername', this.wxdata.userid)
    } else {
      SetUserLocal(
        window.CloundEntid,
        window.CloundEntid,
        userInfo.empcode,
        userInfo.empname,
        userInfo.username,
        localStorage.v8_login_pwd,
        userInfo.empname,
        this.wxdata.existpic,
        userInfo.empid
      )
      setLocVal('spusername', userInfo.username)
    }
    if (window.DingDing && this.userInfo.empid) {
      // 启用钉钉，开始鉴权
      this.$refs.ddBind.checkDdUserid()
      // this.$refs.ddBind.DingDingSign()
    }
    this.checkPwd()
  }
}
</script>

<style scoped lang="less">
.homeContainer {
  height: calc(100vh - 142px);
  overflow: auto;
  .vancol24 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    position: relative;
  }
  .header-top {
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .home_head_qrcode {
      svg {
        width: 36.2px;
        height: 35.2px;
      }
    }
    .home_head_warn {
      margin-left: 15px;
      width: 40px;
      height: 40px;
      position: relative !important;
      top: 0 !important;
      .ee {
        width: 30.38px;
        height: 35.58px;
      }
    }
  }
  .home_blue_qrcode {
    width: 36.2px;
    height: 35.2px;
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .row1 {
    padding: 32px 30px;
    // margin-bottom: 22px;
    background: #2b8df0;
    position: relative;
    z-index: 9;
    .title {
      position: relative;
      color: #ffffff;
      font-family: Source Han Sans SC;
      margin-bottom: 34px;
      text-align: center;
      span {
        font-size: 36px;
      }
      .van-badge__wrapper {
        display: inline-flex;
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        height: 36px;
        /deep/ .van-badge {
          width: 30px;
          height: 30px;
          font-size: 14px;
          line-height: 30px;
          padding: 0;
        }
        /deep/ .van-badge.van-badge--dot {
          width: 16px;
          height: 16px;
          line-height: 14px;
        }
        .svg-icon {
          font-size: 30px;
        }
      }
    }
    .homeGrid {
      font-size: 28px;
      font-family: Source Han Sans;
      color: #ffffff;
      .van-grid-item {
        /deep/.van-grid-item__content {
          background: #2b8df0;
        }
      }
      .svg-icon {
        width: 62px;
        height: 58px;
        margin-bottom: 18px;
        color: #ffffff;
      }
    }
  }
  .newsCenter {
    margin-top: 20px !important;
    margin: 0 30px;
    margin-bottom: 28px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
    .newsImg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.42667rem;
      img {
        width: 100%;
      }
    }
    .newsLists {
      padding: 0.26667rem 0.42667rem;
      .newsDta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .dta_left {
          padding-left: 5px;
          width: 65%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
        .dta_right {
          width: 34%;
          text-align: right;
          font-size: 24px;
          font-family: Helvetica Neue;
          font-weight: bold;
          color: #999999;
        }
      }
      .newsDta::before {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #2b8df0;
        border-radius: 50%;
        content: ' ';
      }
    }
  }
  .row2 {
    // margin-top: -50px !important;
    // position: relative;
    // z-index: 999;
  }
  .row2,
  .row3 {
    margin: 0 30px;
    margin-bottom: 28px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    overflow: hidden;
    .row2Grid,
    .row3Grid {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #333;
      flex-wrap: nowrap !important;
      background: #fff;
      .van-grid-item {
        /deep/.van-grid-item__content {
          padding: 30px 0px;
          span {
            display: inline-block;
            max-width: 5em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .svg-icon {
        width: 74px;
        height: 74px;
        margin-bottom: 12px;
      }
    }
  }
  .row4 {
    margin: 0 30px;
    margin-bottom: 28px;
    // padding-bottom: 14px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    overflow: hidden;
    .van-cell {
      margin-bottom: 18px;
    }
    .row4b_container {
      position: relative;
      padding: 0.1rem 0.68rem 0.42667rem;
      .point1 {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: rgba(43, 141, 240, 0.32);
        position: absolute;
        left: 0.42667rem;
        top: 16px;
      }
      .point2 {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: rgba(43, 141, 240, 0.32);
        position: absolute;
        left: 0.74667rem;
        top: 0px;
      }
    }
    .box_footer_Item {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      img {
        display: block;
        width: 208px;
      }
    }
    .row4b_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-wrap: nowrap !important;
      position: relative;
      z-index: 99;
      // margin-bottom: 16px;
      // padding: 0 0.21333rem;
      .row4_box {
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.33333%;
        height: 130px;
        // margin-bottom: 12px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        opacity: 1;
        .number {
          margin: 0;
          font-size: 50px;
          font-weight: bold;
        }
        .text {
          margin: 0;
          font-size: 24px;
        }
        // border-radius: 12px;
        // span {
        //   display: inline-block;
        //   width: 100%;
        //   font-size: 24px;
        //   box-sizing: border-box;
        //   padding: 0 14px;
        //   font-family: DIN;
        // }
        // span:first-child {
        //   text-align: left;
        // }
        // span:last-child {
        //   text-align: right;
        //   font-size: 40px;
        // }
      }
    }
    .row4Grid {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 112px;
        width: 150px;
        border-radius: 12px;
        span {
          font-size: 24px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .svg-icon {
          width: 74px;
          height: 74px;
          margin-bottom: 12px;
        }
      }
    }
    .row6b_box {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;
      justify-content: flex-start;
      // flex-wrap: nowrap !important;
      position: relative;
      z-index: 99;
      // margin-bottom: 16px;
      // padding: 0 0.21333rem;
      .box_border {
        border-bottom: 0 !important;
      }
      .row4_box {
        border-bottom: 1px solid #ccc;
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.33333%;
        height: 130px;
        // margin-bottom: 12px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        opacity: 1;
        .number {
          margin: 0;
          font-size: 50px;
          font-weight: bold;
        }
        .text {
          margin: 0;
          font-size: 24px;
        }
        // border-radius: 12px;
        // span {
        //   display: inline-block;
        //   width: 100%;
        //   font-size: 24px;
        //   box-sizing: border-box;
        //   padding: 0 14px;
        //   font-family: DIN;
        // }
        // span:first-child {
        //   text-align: left;
        // }
        // span:last-child {
        //   text-align: right;
        //   font-size: 40px;
        // }
      }
    }
  }
  .row5 {
    margin: 0 30px;
    padding-bottom: 10px;
    margin-bottom: 28px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    overflow: hidden;
    .row5b_box {
      display: flex;
      justify-content: space-around;
      margin-bottom: 16px;
      .row5_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        width: 210px;
        height: 90px;
        color: #ffffff;
        background: #2b8df0;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.27);
        opacity: 1;
        border-radius: 12px;
        padding-top: 14px;
        span {
          display: inline-block;
          width: 100%;
          font-size: 24px;
          box-sizing: border-box;
          padding: 0 14px;

          font-family: DIN;
        }
        span:first-child {
          text-align: left;
        }
        span:last-child {
          text-align: right;
          font-size: 40px;
        }
      }
    }
    .row5Grid {
      display: flex;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 128px;
        width: 204px;
        // margin-bottom: 16px;
        border-radius: 12px;
        // .svg-icon {
        //   margin-bottom: 16px;
        // }
        .svg-icon {
          width: 74px;
          height: 74px;
          margin-bottom: 12px;
        }
        span {
          font-size: 24px;
          font-family: Source Han Sans CN;
          color: #000;
        }
      }
    }
  }
  .row6 {
    margin: 0 30px;
    margin-bottom: 28px;
    padding-bottom: 14px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    overflow: hidden;
    .van-cell {
      margin-bottom: 18px;
    }
    .row6Grid {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 112px;
        width: 150px;
        border-radius: 12px;
        span {
          font-size: 24px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .svg-icon {
          width: 74px;
          height: 74px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
